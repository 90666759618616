<template>
  <div class="infos-list">
    <div class="d-flex justify-content-between align-items-center">
      <p class="h4 pt-4 pb-4">{{ $route.meta.title }}</p>
      <el-button class="submit-button" @click="routerTo()">新增</el-button>
    </div>
    <el-table
      :data="tips"
      style="width: 100%"
      border>
      <el-table-column type="index" label="序号" width="80" header-align="center" align="center"></el-table-column>
      <el-table-column label="标题" header-align="center">
        <template slot-scope="scope">
          <div v-html="scope.row.title" class="title"></div>
        </template>
      </el-table-column>
      <el-table-column label="发布时间" width="130" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ formatTime(scope.row.publishedAt) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80" header-align="center" align="center">
        <template slot-scope="scope">
          <el-button class="delete" size="small" type="text" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { delTip, tipLists } from '@/api/tips'

export default {
  name: 'task',
  inject: ['reload'],
  data () {
    return {
      tips: []
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getTips()
    })
  },
  methods: {
    /**
     * 高亮第一条
     */
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex <= 4) {
        return 'bg-sm-blue'
      }
      return ''
    },
    /**
     * 跳转路由，添加与编辑分情况
     */
    routerTo () {
      const path = this.$route.name + '/edit-infos'
      this.$router.push(path)
    },
    handleTop (index, row) {
      console.log(index, row)
    },
    handleDelete (id) {
      this.$confirm('此操作将永久删除该公告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTip(id).then(
          () => {
            this.reload()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          }
        )
      }).catch(() => {
        console.log('已取消删除')
      })
    },
    formatTime (timestamp) {
      const d = new Date(timestamp * 1000)
      const YYYY = d.getFullYear()
      const MM = d.getMonth() + 1
      const DD = d.getDate()
      return YYYY + '-' + MM + '-' + DD
    },
    getTips () {
      const type = this.$route.name === 'task' ? 2 : 1
      tipLists(type).then(
        res => {
          this.tips = res.data.items
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
.infos-list {
  .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .delete {
    color: #F56C6C;
  }
  .el-table {
    color: #555;
    border-bottom: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    /deep/thead {
      color: #333;
    }
  }
  /deep/.el-table--border td,
  /deep/.el-table--border th,
  /deep/.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
  /deep/.el-table td, .el-table th.is-leaf,
  /deep/.el-table--border, .el-table--group {
    border-color: #D9D9D9;
  }
}
</style>
